import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Table,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { atualizaUsuario, changeUserTypeToCollaborator, checkMaxPessoas, deletaUsuario, getPessoa, listaUsuariosFilter, resetAcessoUsuario, verifyCPFIsAlreadyRegistered } from './configs/functions';
import { AdminSettingMaleIcon } from '../../../common/Icons';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { UserForm } from './components/UserForm';
import { UserRow } from './components/UserRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { decrypt } from '../../../../utils/cryptoUtils';
import { UserModalInfo } from './components/UserModalInfo';
import { UsersTableFilter } from './components/UsersTableFilter';
import { Pagination } from '../../../common/Pagination';
import { listaEmpresas } from '../companies/configs/functions';
import { FiltersButton } from '../../../common/FiltersButton';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { useModuleContext } from '../../../../contexts/ModuleProvider';
import { CustomToolbarExportTable } from '../../../common/CustomToolbarExportTable';
import { extractErrorMessage } from '../../../../utils/extract-error-message';

function ListOfUsers() {
    const queryClient = useQueryClient();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();
    const { verifyIntegrationMode } = useModuleContext();

    const [searchParams, setSearchParams] = useSearchParams();

    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();

    const [open, setFormOpen] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDialogResetUser, setOpenDialogResetUser] = useState(false);
    const [openDialogTurnUserCollaborator, setOpenDialogTurnUserCollaborator] = useState(false);

    const [moduleActionsAllowed, setModuleActionsAllowed] = useState(false);

    const searchParamPage = searchParams.get('page');
    const searchParamPerPage = searchParams.get('perPage');

    // estados para controle de paginacao
    const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
    const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(0);

    //controla o idEmpresa para casos de abrir o form de edicao
    const [user, setUser] = useState(null);

    const columns = useMemo(() => [
        { field: 'matricula', headerName: 'Matrícula' },
        { field: 'nome', headerName: 'Nome' },
        { field: 'cpf', headerName: 'CPF' },
        { field: 'email', headerName: 'E-mail' },
        { field: 'dtNascimento', headerName: 'Data de Nascimento' },
        { field: 'empresa.fantasyName', headerName: 'Empresa', valueGetter: (params) => params.row.empresa?.fantasyName },
        { field: 'status', headerName: 'Status', valueGetter: (params) => params.row.status === 1 ? 'Ativo' : 'Inativo' },
    ], []);

    const { data: empresasData, error: listaEmpresasError } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: async () => {
            const response = await listaEmpresas();
            const empresasData = response.data.data;

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);

            setSearchParams((state) => {
                state.set('id_empresa', String(selectedEmpresaId));
                state.set('page', String(page + 1));
                state.set('perPage', String(rowsPerPage));

                return state;
            });
            return response;
        },
        refetchOnWindowFocus: false,
    });

    const queryFilters = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        tipo_usuario: 'administrador',
        email: searchParams.get('email'),
        nome: searchParams.get('nome'),
        matricula: searchParams.get('matricula'),
        id_empresa: searchParams.get('id_empresa'),
    };

    const queryKeyListUsers = ['listOfUsers', queryFilters];

    const { data: usersData, isLoading, isPending, error: listOfUsersError } = useQuery({
        queryKey: queryKeyListUsers,
        queryFn: () => listaUsuariosFilter(queryFilters),
        enabled: !!searchParamPage && !!searchParamPerPage,
    });

    const usuarios = usersData?.data?.data ?? [];
    const numTotalItems = usersData?.data?.numero_total ?? 0;
    const isLoadingList = isLoading || isPending;

    // get error
    if (usersData?.error) {
        console.error('Erro ao carregar usuários:', usersData.error);
        exibirAlerta('Ops', 'Erro ao carregar Usuários, tente novamente mais tarde.', 'error');
    }

    const { data: isMaxUsuarios } = useQuery({
        queryKey: ['checkMaxUsuarios', searchParams.get('id_empresa'), numTotalItems],
        queryFn: () => checkMaxPessoas(searchParams.get('id_empresa'), empresasData?.data?.data, 'colaborador'),
        enabled: !!searchParams.get('id_empresa') && empresasData?.data?.data.length > 0,
        refetchOnWindowFocus: false,
    });

    const getUser = async (encryptedId) => {
        const pessoaId = decrypt(encryptedId);

        if (!pessoaId) {
            exibirAlerta('Erro', 'Erro ao carregar usuário.', 'error');
            return;
        }

        try {
            exibirDialog('Buscando informações do Usuário. Aguarde...');
            const res = await getPessoa(pessoaId);
            const user = res.data;
            handleEditUser(user);
        } catch (error) {
            const messageError = error.response?.data?.error || 'Erro ao carregar usuário.';
            exibirAlerta('Erro', messageError, 'error');
        } finally {
            handleCloseDialog();
        }
    }

    function handleViewUser(user) {
        setUser(user);
        setOpenViewModal(true);
    }

    function handleEditUser(user) {
        setUser(user);
        setFormOpen(true);
    }

    const handleOpenDeleteDialog = (item) => {
        setUser(item);
        setOpenDeleteDialog(true);
    }

    const handleOpenDialogResetUser = (item) => {
        setUser(item);
        setOpenDialogResetUser(true);
    }

    const handleOpenDialogTurnUserCollaborator = (item) => {
        setUser(item);
        setOpenDialogTurnUserCollaborator(true);
    }

    const onUpdateStatus = async (updatedUser) => {
        const dataSendUpdate = { id: updatedUser.id, dataToUpdate: { status: !updatedUser.status } };

        if (updatedUser.cpf && !updatedUser.status) {
            const userExists = await verifyCPFIsAlreadyRegistered(updatedUser.cpf, updatedUser.empresa.id);
            if (userExists) {
                exibirAlerta(
                    'CPF já cadastrado!',
                    'Já existe um usuário ativo com esse CPF. Não é permitido ter dois usuários ativos com o mesmo CPF.',
                    'error'
                );
                return;
            }
        }

        updateStatusFn(dataSendUpdate)
    }

    const { mutateAsync: updateStatusFn, isPending: isPendingUpdateStatus } = useMutation({
        mutationFn: (data) => atualizaUsuario(data.id, data.dataToUpdate),
        onSuccess: (response, variables, context) => {
            const user = response.data.data;

            const message = user.status ? 'Usuário ativado com sucesso.' : 'Usuário inativado com sucesso.';
            exibirAlerta('Sucesso', message, 'success');

            updateCachedData(user);
        },
    });

    const { mutateAsync: handleDelete } = useMutation({
        mutationFn: deletaUsuario,
        onSuccess: (response, id) => {
            exibirAlerta('Sucesso', 'Usuário inativado com sucesso.', 'success');
            queryClient.invalidateQueries({ queryKey: queryKeyListUsers });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDeleteDialog(false);
        },
    });

    const { mutateAsync: handleResetAcesso } = useMutation({
        mutationFn: resetAcessoUsuario,
        onSuccess: (response) => {
            exibirAlerta('Sucesso', 'Credenciais resetadas com sucesso.', 'success');
            const updatedUser = response.data.data;
            updateCachedData(updatedUser);
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDialogResetUser(false);
        },
    });

    const { mutateAsync: handleChangeUserTypeToCollaborator } = useMutation({
        mutationFn: changeUserTypeToCollaborator,
        onSuccess: (response) => {
            exibirAlerta('Sucesso', 'Usuário alterado para Colaborador com sucesso.', 'success');
            queryClient.invalidateQueries({ queryKey: queryKeyListUsers });
        },
        onError: (error) => {
            const message = error.response?.data?.message ?? 'Erro ao executar operação';
            exibirAlerta('Erro', message, 'error');
        },
        onSettled: () => {
            setUser(null);
            setOpenDialogTurnUserCollaborator(false);
        },
    });

    function updateCachedData(updatedUser) {
        // buscar em cache os dados da requisição e atualizar
        const cached = queryClient.getQueryData(queryKeyListUsers);
        const cachedData = cached.data;

        if (cached) {
            const updatedData = cachedData.data.map(item => {
                if (item.id === updatedUser.id) {
                    return updatedUser;
                }

                return item;
            });

            queryClient.setQueryData(queryKeyListUsers, {
                ...cached,
                data: {
                    ...cachedData,
                    data: updatedData
                }
            });
        }
    }

    useEffect(() => {
        if (open) return;
        setUser(null);

        const queryFilters = {
            ...Object.fromEntries(searchParams),
        }

        const tableFilters = { ...queryFilters };
        delete tableFilters.page;
        delete tableFilters.perPage;
        delete tableFilters.option;
        delete tableFilters.openEditForm;

        const activeFilters = Object.keys(tableFilters).length;

        setActiveFilters(activeFilters);
        setIsOpenFilters(activeFilters > 0);

        const empresaHasActionsAllowed = verifyIntegrationMode("Pessoa", searchParams.get('id_empresa'));
        setModuleActionsAllowed(empresaHasActionsAllowed);
    }, [open, searchParams, verifyIntegrationMode]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_pessoas", "list_pessoas", "view_pessoas"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        // add verification ability view_pessoas
        const encryptedId = decodeURIComponent(searchParams.get('option'));
        const openUserForm = searchParams.get('openEditForm');

        if (encryptedId && openUserForm === '1') {
            getUser(encryptedId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let errorMessage = "";

        if (listOfUsersError) {
            errorMessage = extractErrorMessage(listOfUsersError, 'Erro ao carregar a lista de usuários');
        } else if (listaEmpresasError) {
            errorMessage = extractErrorMessage(listaEmpresasError, 'Erro ao carregar a lista de empresas');
        }

        errorMessage && exibirAlerta('Ops! Ocorreu um erro', errorMessage, 'error');
    }, [listOfUsersError, listaEmpresasError, exibirAlerta]);

    return (
        <>
            <Helmet title="Usuários Administradores" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="end"
                spacing={3}
            >
                <Grid item xs={12} md={9}>
                    <Breadcrumbs>
                        <LinkNavegacao to="/"><HomeIcon fontSize="small" /></LinkNavegacao>
                        <Typography variant='span'>Gerenciamento</Typography>
                        <LinkNavegacao to="/gerenciamento/pessoas">Pessoas</LinkNavegacao>
                        <Typography variant='span'>Usuários do Sistema</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<AdminSettingMaleIcon fontSize="large" />}
                        title='Usuários do Sistema'
                        description='Administre os usuários do sistema e suas permissões.'
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                        <FiltersButton
                            isOpenFilters={isOpenFilters}
                            setIsOpenFilters={setIsOpenFilters}
                            activeFilters={activeFilters}
                        />

                        {hasPermission(["admin", "admin_pessoas", "create_pessoas"]) &&
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setFormOpen(true)}
                            >
                                Cadastrar Usuário
                            </Button>
                        }
                    </Stack>
                </Grid>

                <UsersTableFilter
                    isOpenFilters={isOpenFilters}
                    isLoadingList={isLoadingList}
                    empresas={empresasData?.data?.data ?? []}
                />

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <CustomToolbarExportTable
                            rows={usuarios}
                            columns={columns}
                            reportName="relatorio_administradores"
                            reportPdfTitle="RELATÓRIO DE USUÁRIOS ADMINISTRADORES"
                            getDataWithoutPaginate={() => {
                                const { limit, offset, ...data } = queryFilters;
                                return listaUsuariosFilter(data);
                            }}
                            getSelectedEmpresa={() => empresasData.data.data.find(empresa => empresa.id === Number(searchParams.get('id_empresa')))}
                        />

                        <Table aria-label="Usuários">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Matrícula</TableCell>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Empresa</TableCell>
                                    <TableCell>Usuário</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingList ? (
                                    <SkeletonTableRow
                                        numCells={7}
                                        numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                                    />
                                ) : (
                                    usuarios && usuarios.length === 0 ? (
                                        <EmptyTableRow infoType='Usuário Administrativo' />
                                    ) : (
                                        usuarios.map((user) =>
                                            <UserRow
                                                key={user.id}
                                                user={user}
                                                actionsAllowed={moduleActionsAllowed}
                                                isMaxUsuarios={isMaxUsuarios}
                                                isPendingUpdate={isPendingUpdateStatus}
                                                handleUpdateStatus={() => onUpdateStatus(user)}
                                                handleOpenViewModal={() => handleViewUser(user)}
                                                handleOpenEditForm={() => handleEditUser(user)}
                                                handleOpenDeleteDialog={() => handleOpenDeleteDialog(user)}
                                                handleOpenDialogResetUser={() => handleOpenDialogResetUser(user)}
                                                handleOpenDialogTurnUserCollaborator={() => handleOpenDialogTurnUserCollaborator(user)}
                                            />
                                        )
                                    )
                                )}
                            </TableBody>
                        </Table>

                        <Pagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <UserForm
                open={open}
                setOpen={(event) => {
                    setFormOpen(event);
                    // remove params url (openEditForm and option)
                    setSearchParams((state) => {
                        state.delete('openEditForm');
                        state.delete('option');
                        return state;
                    });
                }}
                isMaxLimitTipoPessoa={isMaxUsuarios}
                pessoa={user}
                userType='administrador'
                queryKey={queryKeyListUsers}
                actionsAllowed={moduleActionsAllowed}
            />

            <UserModalInfo
                open={openViewModal}
                setOpen={(event) => {
                    setOpenViewModal(event)
                    if (!event) setUser(null);
                }}
                pessoa={user}
            />

            <ConfirmDialog
                description='Tem certeza que deseja resetar as credenciais de acesso desse Usuário? Isso removerá a senha e a Biometria facial cadastrada.'
                title='Resetar Credenciais'
                goAction={() => handleResetAcesso(user.id)}
                handleClose={() => {
                    setOpenDialogResetUser(false);
                    setUser(null);
                }}
                state={openDialogResetUser}
            />

            <ConfirmDialog
                description='Tem certeza que deseja inativar esse Usuário?'
                title='Inativar Usuário'
                goAction={() => handleDelete(user.id)}
                handleClose={() => {
                    setOpenDeleteDialog(false);
                    setUser(null);
                }}
                state={openDeleteDialog}
            />

            <ConfirmDialog
                title='Remover permissões de Administrador'
                description={
                    <>
                        <Typography>Tem certeza que deseja remover permissões de Administrador desse usuário?</Typography>
                        <Typography>Ele será alterado para Colaborador.</Typography>
                    </>
                }
                goAction={() => handleChangeUserTypeToCollaborator(user.id)}
                handleClose={() => {
                    setOpenDialogTurnUserCollaborator(false);
                    setUser(null);
                }}
                state={openDialogTurnUserCollaborator}
                confirmText='Sim, tornar Colaborador'
            />
        </>
    );
}

export default ListOfUsers;