import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Home from '../components/screens/epis/home';
import Products from '../components/screens/epis/products';
import Guidelines from '../components/screens/epis/guidelines';
import Reports from '../components/screens/epis/reports';
import Companies from '../components/screens/management/companies';
import People from '../components/screens/management/people';
import Organization from '../components/screens/management/organization';
import Settings from '../components/screens/management/settings';
import Alerts from '../components/screens/epis/alerts';
import Details from '../components/screens/management/details';
import Trainings from '../components/screens/training/trainings';
import Events from '../components/screens/training/events';
import AlertsTraining from '../components/screens/training/alerts/index'
import EquipmentsTraining from '../components/screens/training/equipments/index'
import ListOfUsers from '../components/screens/management/people/ListOfUsers';
import ListOfCollaborators from '../components/screens/management/people/ListOfCollaborators';
import Areas from '../components/screens/management/organization/Areas';
import Sectors from '../components/screens/management/organization/Sectors';
import ListOfProducts from '../components/screens/epis/products/ListofProducts';
import ListOfCategories from '../components/screens/epis/products/ListOfCategories';
import { ListOfSizes } from '../components/screens/epis/products/ListOfSizes';
import ListOfUnitsOfMeasure from '../components/screens/epis/products/ListOfUnitsofMeasure';
import { ListOfEquipment } from '../components/screens/epis/equipments/ListOfEquipment';
import { ListOfPontosEntrega } from '../components/screens/epis/equipments/ListOfPontosEntrega';
import Departments from '../components/screens/management/organization/Departments';
import { PageNotFound } from '../components/screens/PageNotFound';
import ListOfGHEs from '../components/screens/epis/guidelines/ListOfGHEs';
import { ListOfStock } from '../components/screens/epis/equipments/ListOfStock';
import { ListOfJustificativasRequisicao } from '../components/screens/epis/guidelines/ListOfJustificativasRequisicao';
import { ListOfCostCenter } from '../components/screens/management/organization/ListOfCostCenter';
import { Dashboard } from '../components/screens/epis/Dashboard';
import { ConfigAlerts } from '../components/screens/epis/alerts/ConfigAlerts';
import { ListOfAlerts } from '../components/screens/epis/alerts/ListOfAlerts';
import { FichaEPI } from '../components/screens/epis/reports/FichaEPI';
import { AbastecimentoMaquina } from '../components/screens/epis/reports/AbastecimentoMaquina';
import { SaldosMaquina } from '../components/screens/epis/reports/SaldosMaquina';
import { RelatorioRetiradas } from '../components/screens/epis/reports/RelatorioRetiradas';
import { RelatorioRetiradasExtras } from '../components/screens/epis/reports/RelatorioRetiradasExtras';
import { RelatorioRetiradasFuncionario } from '../components/screens/epis/reports/RelatorioRetiradasFuncionario';
import { SaidaProdutosFuncionario } from '../components/screens/epis/reports/SaidaProdutosFuncionario';
import { SaidaProdutos } from '../components/screens/epis/reports/SaidaProdutos';
import { ListOfFunctions } from '../components/screens/management/people/ListOfFunctions';
import { ListOfDocumentType } from '../components/screens/management/organization/ListOfDocumentType';
import { DevolucaoRetiradas } from '../components/screens/epis/products/DevolucaoRetiradas';
import { Devolucoes } from '../components/screens/epis/products/Devolucoes';
import { ListOfJustificativasDevolucao } from '../components/screens/epis/guidelines/ListOfJustificativasDevolucao';
import { Retiradas } from '../components/screens/epis/retiradas';
import { ListOfRetiradasExtras } from '../components/screens/epis/retiradas/ListOfRetiradasExtras';
import { OrganizationSempher } from '../components/screens/admin/OrganizationSempher';
import { ListOfAbilities } from '../components/screens/admin/abilities/ListOfAbilities';
import { ListOfRoles } from '../components/screens/admin/abilities/ListOfRoles';
import { AdminPanel } from '../components/screens/admin/AdminPanel';
import { RelatorioPendenciasRetiradas } from '../components/screens/epis/reports/RelatorioPendenciasRetiradas';
import { ListOfWorkRegimes } from '../components/screens/management/organization/ListOfWorkRegimes';
import { SpreadsheetImport } from '../components/screens/admin/SpreadsheetImport';
import { AdminProtectRoute } from './AdminProtectRoute';
import { RelatorioSaldoPessoa } from '../components/screens/epis/reports/RelatorioSaldoPessoa';
import { Integration } from '../components/screens/management/settings/Integration';
import { ListOfModules } from '../components/screens/admin/abilities/ListOfModules';
import { ListOfExports } from '../components/screens/management/settings/ListOfExports';
import { ListOfExportsTypes } from '../components/screens/management/settings/ListOfExportsTypes';
import { ListOfConfigEmpresa } from '../components/screens/management/settings/ListOfConfigEmpresa';

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" index element={<Home />} />

      <Route path="/epis" index element={<Dashboard />} />

      { /**
                 * MÓDULO DOS EPIs
                 *
                 *
                 * Grupo Products */}
      <Route path="/epis/produtos" index element={<Products />} />
      <Route path="/epis/produtos/listadeprodutos" index element={<ListOfProducts />} />
      <Route path="/epis/produtos/listadecategorias" index element={<ListOfCategories />} />
      <Route path="/epis/produtos/listadetamanhos" index element={<ListOfSizes />} />
      <Route path="/epis/produtos/listadeunidadesdemedidas" index element={<ListOfUnitsOfMeasure />} />

      {/* Retiradas */}
      <Route path="/epis/retiradas" index element={<Retiradas />} />
      <Route path="/epis/retiradas/retiradas_extras" index element={<ListOfRetiradasExtras />} />
      <Route path="/epis/retiradas/devolucoes" index element={<Devolucoes />} />
      <Route path="/epis/retiradas/devolucao_retirada" index element={<DevolucaoRetiradas />} />
      <Route path="/epis/retiradas/justificativa_requisicao" index element={<ListOfJustificativasRequisicao />} />
      <Route path="/epis/retiradas/justificativa_devolucao" index element={<ListOfJustificativasDevolucao />} />

      { /** Grupo Guidelines */}
      <Route path="/epis/diretrizes" index element={<Guidelines />} />
      <Route path="/epis/diretrizes/ghes" index element={<ListOfGHEs />} />

      { /** Grupo Equipamentos */}
      {/* <Route path="/epis/equipamentos" index element={<Equipments />} /> */}
      <Route path="/epis/equipamentos" index element={<ListOfEquipment />} />
      {/* <Route path="/epis/equipamentos/gerenciamento_equipamentos" index element={<ListOfEquipment />} /> */}

      { /** Grupo Alertas */}
      <Route path="/epis/alertas" index element={<Alerts />}></Route>
      <Route path="/epis/alertas/config_alertas" index element={<ConfigAlerts />} />
      <Route path="/epis/alertas/lista_alertas" index element={<ListOfAlerts />} />

      { /** Grupo Relatórios */}
      <Route path="/epis/relatorios" index element={<Reports />} />
      <Route path="/epis/relatorios/saidas_produtos" index element={<SaidaProdutos />} />
      <Route path="/epis/relatorios/entregas_produtos_responsavel" index element={<SaidaProdutosFuncionario />} />
      <Route path="/epis/relatorios/retiradas" index element={<RelatorioRetiradas />} />
      <Route path="/epis/relatorios/retiradas_extras" index element={<RelatorioRetiradasExtras />} />
      <Route path="/epis/relatorios/retiradas_funcionario" index element={<RelatorioRetiradasFuncionario />} />
      <Route path="/epis/relatorios/pendencias_retiradas" index element={<RelatorioPendenciasRetiradas />} />
      <Route path="/epis/relatorios/ficha_epi_colaborador" index element={<FichaEPI />} />
      <Route path="/epis/relatorios/abastecimento_maquina" index element={<AbastecimentoMaquina />} />
      <Route path="/epis/relatorios/saldos_maquina" index element={<SaldosMaquina />} />
      <Route path="/epis/relatorios/saldo_pessoa" index element={<RelatorioSaldoPessoa />} />

      { /**
                 * MÓDULO TREINAMENTO
                 *
                 *
                 * Grupo Treinamentos */}
      <Route path="/treinamentos/treinamentos" index element={<Trainings />}>
      </Route>
      { /** Grupo Eventos */}
      <Route path="/treinamentos/eventos" index element={<Events />}>
      </Route>
      { /** Grupo Equipamentos */}
      <Route path="/treinamentos/equipamentos" index element={<EquipmentsTraining />}>
      </Route>
      { /** Grupo Alertas */}
      <Route path="/treinamentos/alertas" index element={<AlertsTraining />}>
      </Route>


      {/** GRUPO DE GERENCIAMENTO */}
      <Route path="/gerenciamento">
        <Route path="empresas" exact element={<Companies />} />

        <Route path="pessoas" element={<People />} />
        <Route path="pessoas/usuarios" element={<ListOfUsers />} />
        <Route path="pessoas/colaboradores" element={<ListOfCollaborators />} />
        <Route path="pessoas/funcoes" element={<ListOfFunctions />} />

        <Route path="organizacao" element={<Organization />} />
        <Route path="organizacao/departamentos" element={<Departments />} />
        <Route path="organizacao/areas" element={<Areas />} />
        <Route path="organizacao/setores" element={<Sectors />} />
        <Route path="organizacao/centros_de_custo" element={<ListOfCostCenter />} />
        <Route path="organizacao/pontos_de_entrega" element={<ListOfPontosEntrega />} />
        <Route path="organizacao/regimes_de_trabalho" element={<ListOfWorkRegimes />} />
        <Route path="organizacao/tipos_de_documento" element={<ListOfDocumentType />} />
        <Route path="organizacao/estoque" element={<ListOfStock />} />

        <Route path="configuracoes" element={<Settings />} />
        <Route path="configuracoes/integracao" element={<Integration isAdminSempher={false} />} />
        {/* <Route path="configuracoes/exportacao" element={<ListOfExports />} /> */}
        {/* <Route path="configuracoes/configuracoes-exportacao" element={<ListOfExportsTypes />} /> */}
        <Route path="configuracoes/configuracao-empresa" element={<ListOfConfigEmpresa />} />

        <Route path="detalhes" element={<Details />} />
      </Route>

      <Route path="/admin" element={<AdminProtectRoute />}>
        <Route path="painel" element={<AdminPanel />} />
        <Route path="organizacao" element={<OrganizationSempher />} />
        <Route path="organizacao/modulos" element={<ListOfModules />} />
        <Route path="organizacao/roles" element={<ListOfRoles />} />
        <Route path="organizacao/abilities" element={<ListOfAbilities />} />
        <Route path="organizacao/integracao" element={<Integration isAdminSempher={true} />} />
        <Route path="importacao" element={<SpreadsheetImport />} />
      </Route>

      {/* Rota de Fallback. 404 Página não encontrada */}
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  )
}
